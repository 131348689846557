import React from 'react'

import { ManageModalType } from '../../../intefraces'

import useAppState from '../../../services/appState'

import { modalBlueBtnBorder, modalPurpleBtnBorder, maxBtnBorder } from '../../../content/svgs'

import './deposit-menu.scss'

const DepositMenu = () => {
  const setManageModalType = useAppState(state => state.setManageModalType)
  const setSnackbarMessage = useAppState(state => state.setSnackbarMessage)

  return (
    <div className="deposit-menu">
      <div className="amount-input">
        <div className="label">Amount:</div>
        <div className="input"><input type="number" value="1607285034"/></div>
        <div className="max-btn-wrapper">
          <div className="max-btn">
            <span>MAX</span>
            <div className="max-btn-bg" dangerouslySetInnerHTML={{ __html: maxBtnBorder }} />
          </div>
        </div>
      </div>
      <div className="deposit-hint">You will recieve on $PIGGY per War Pig. Transaction fee apply.</div>
      <div
        className="approve-btn"
        onClick={() => {
          setSnackbarMessage({
            mainTxt: 'Approved!',
            additionalTxt: 'Language goes here ..'
          })
        }}
      >
        <span>Approve</span>
        <div className="approve-btn-bg" dangerouslySetInnerHTML={{ __html: modalBlueBtnBorder }}/>
      </div>
      <div className="deposit-btn">
        <span>Deposit</span>
        <div className="deposit-btn-bg" dangerouslySetInnerHTML={{ __html: modalPurpleBtnBorder }}/>
      </div>
      <div className="modal-close-txt" onClick={() => { setManageModalType(ManageModalType.MenuSelect) }}>CANCEL</div>
    </div>
  )
}

export default DepositMenu
