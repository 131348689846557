import React from 'react'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import CloseIcon from '@material-ui/icons/Close'

import useAppState from '../../services/appState'

import modalBg from '../../content/modal-bg.png'
import { modalDefaultBtnBorder } from '../../content/svgs'

import './connect.scss'

const ConnectModal = () => {
  const connectModalOpened = useAppState(state => state.connectModalOpened)
  const setConnectModalOpened = useAppState(state => state.setConnectModalOpened)

  return (
    <>
      <Modal
        open={connectModalOpened}
        BackdropComponent={Backdrop}
        className="connect-modal modal"
        onClose={() => { setConnectModalOpened(false) }}
      >
        <div className="modal-content">
          <CloseIcon className="modal-close-btn" onClick={() => { setConnectModalOpened(false) }}/>
          <div className="title">Connect Your Wallet</div>
          <div className="info-text">By connecting a wallet, you agree to Volume Wars <a href="#">Terms of Service</a> and acknowledge that you have read and understand the Volume Wars <a href="#">disclaimer</a>.</div>
          <div>
            <div className="item" onClick={() => { setConnectModalOpened(false) }}>
              <span>MetaMask <img src="/content/services/metamask.png" alt="MetaMask" /></span>
              <div className="item-bg" dangerouslySetInnerHTML={{ __html: modalDefaultBtnBorder }}/>
            </div>
            <div className="item" onClick={() => { setConnectModalOpened(false) }}>
              <span>WalletConnect <img src="/content/services/walletconnect.png" alt="WalletConnect" /></span>
              <div className="item-bg" dangerouslySetInnerHTML={{ __html: modalDefaultBtnBorder }}/>
            </div>
            <div className="item" onClick={() => { setConnectModalOpened(false) }}>
              <span>Coinbase Wallet <img src="/content/services/metamask.png" alt="Metamask" /></span>
              <div className="item-bg" dangerouslySetInnerHTML={{ __html: modalDefaultBtnBorder }}/>
            </div>
          </div>
          <div className="modal-close-txt"><span onClick={() => { setConnectModalOpened(false) }}>CANCEL</span></div>
          <img className="modal-bg" src={modalBg} />
        </div>
      </Modal>
    </>
  )
}

export default ConnectModal
