import React from 'react'

import { ManageModalType } from '../../../intefraces'

import useAppState from '../../../services/appState'

import { modalBlueBtnBorder } from '../../../content/svgs'

import './buy-menu.scss'

const BuyMenu = () => {
  const setManageModalType = useAppState(state => state.setManageModalType)

  return (
    <div className="buy-menu">
      <div className="buy-desc">
        <div className="buy-desc-1">VolumeWars will buy $PIGGY with BNB from PancakeSwap and credit them to your VolumeWars account as War Pigs.</div>
        <div className="buy-desc-2">This lets you avoid paying the $PIGGY transaction fee twice</div>
      </div>
      <div className="buy-btns">
        <div className="buy-btn">
          <div className="buy-btn-txt1">BUY 0.01 BNB</div>
          <div className="buy-btn-txt2">1 000 000 000 $PIGGY</div>
          <div className="buy-btn-bg" dangerouslySetInnerHTML={{ __html: modalBlueBtnBorder }}/>
        </div>
        <div className="buy-btn">
          <div className="buy-btn-txt1">BUY 0.05 BNB</div>
          <div className="buy-btn-txt2">5 000 000 000 $PIGGY</div>
          <div className="buy-btn-bg" dangerouslySetInnerHTML={{ __html: modalBlueBtnBorder }}/>
        </div>
        <div className="buy-btn">
          <div className="buy-btn-txt1">BUY 0.1 BNB</div>
          <div className="buy-btn-txt2">10 000 000 000 $PIGGY</div>
          <div className="buy-btn-bg" dangerouslySetInnerHTML={{ __html: modalBlueBtnBorder }}/>
        </div>
        <div className="buy-btn">
          <div className="buy-btn-txt1">BUY 0.2 BNB</div>
          <div className="buy-btn-txt2">20 000 000 000 $PIGGY</div>
          <div className="buy-btn-bg" dangerouslySetInnerHTML={{ __html: modalBlueBtnBorder }}/>
        </div>
      </div>
      <div className="modal-close-txt" onClick={() => { setManageModalType(ManageModalType.MenuSelect) }}>CANCEL</div>
    </div>
  )
}

export default BuyMenu
