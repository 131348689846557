import React from 'react'

import { ManageModalType } from '../../../intefraces'

import useAppState from '../../../services/appState'

import { modalRedBtnBorder, maxBtnBorder } from '../../../content/svgs'

import './withdraw-menu.scss'

const WithdrawMenu = () => {
  const setManageModalType = useAppState(state => state.setManageModalType)

  return (
    <div className="withdraw-menu">
      <div className="amount-input">
        <div className="label">Amount:</div>
        <div className="input"><input type="number" value="1607285034"/></div>
        <div className="max-btn-wrapper">
          <div className="max-btn">
            <span>MAX</span>
            <div className="max-btn-bg" dangerouslySetInnerHTML={{ __html: maxBtnBorder }} />
          </div>
        </div>
      </div>
      <div className="withdraw-hint">You will recieve on $PIGGY per War Pig. Transaction fee apply.</div>
      <div className="withdraw-btn">
        <span>Withdraw</span>
        <div className="withdraw-btn-bg" dangerouslySetInnerHTML={{ __html: modalRedBtnBorder }}/>
      </div>
      <div className="modal-close-txt" onClick={() => { setManageModalType(ManageModalType.MenuSelect) }}>CANCEL</div>
    </div>
  )
}

export default WithdrawMenu
