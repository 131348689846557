import { useEffect, MutableRefObject } from 'react'

export const formatNumberToString = (number: number, length?: number) => {
  let numberStr = number.toString()
  if (length && numberStr.length < length) {
    for (let i = 0; i < length - numberStr.length; ++i) {
      numberStr = '0' + numberStr
    }
  }
  return numberStr
}

export const bigNumberFormatter = (num: number) => {
  return Math.abs(num) > 999999999
    ? Math.sign(num) * parseFloat(((Math.abs(num) / 1000000000).toFixed(3))) + ' B'
    : Math.abs(num) > 999999
      ? Math.sign(num) * parseFloat(((Math.abs(num) / 1000000).toFixed(3))) + ' M'
      : Math.abs(num) > 999
        ? Math.sign(num) * (parseFloat((Math.abs(num) / 1000).toFixed(3))) + ' K'
        : Math.sign(num) * Math.abs(num)
}

export const deepCopy = <T>(obj: T) => {
  return JSON.parse(JSON.stringify(obj)) as T
}

export const useOutsideClick = (ref: MutableRefObject<HTMLDivElement | null>, callback: Function) => {
  const handleClick = (e: MouseEvent) => {
    if (
      ref.current &&
      e.target instanceof HTMLDivElement &&
      !ref.current.contains(e.target)
    ) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}
