import React, { Suspense } from 'react'
import {
  Route,
  Switch,
  BrowserRouter as Router
} from 'react-router-dom'

import './styles.scss'

import Header from '../header'
import Snackbar from '../snackbar'
import Page from './page'
const Home = React.lazy(() => import('../home'))
const Locker = React.lazy(() => import('../locker'))
const WarRoom = React.lazy(() => import('../war-room'))
const HowToPlay = React.lazy(() => import('../how-to-play'))
const Leaderboard = React.lazy(() => import('../leaderboard'))

const App = () => {
  return (
    <>
      <Router>
        <Header/>
        <Switch>
          <Route exact path='/'>
            <Suspense fallback={<Page></Page>}><Page><Home /></Page></Suspense>
          </Route>
          <Route exact path='/locker'>
          <Suspense fallback={<Page></Page>}>
            <Page
              title="NFT Locker"
              subtitle="Claim Rewards & Booster Pack, Forge Legendary NFTs!"
            >
              <Locker />
            </Page>
          </Suspense>
          </Route>
          <Route exact path='/war-room'>
            <Suspense fallback={<Page></Page>}><Page><WarRoom /></Page></Suspense>
          </Route>
          <Route exact path='/how-to-play'>
            <Suspense fallback={<Page></Page>}>
              <Page
                title="Information"
                subtitle="Information to be determined"
              >
                <HowToPlay />
              </Page>
            </Suspense>
          </Route>
          <Route exact path='/leaderboard/:season?/:type?'>
            <Suspense fallback={<Page></Page>}>
              <Page title="Leaderboard">
                <Leaderboard />
              </Page>
            </Suspense>
          </Route>
        </Switch>
      </Router>
      <Snackbar />
    </>
  )
}

export default App
