import React from 'react'

import { ManageModalType } from '../../../intefraces'

import useAppState from '../../../services/appState'

import { modalBlueBtnBorder, modalPurpleBtnBorder, modalRedBtnBorder } from '../../../content/svgs'

import './menu-select.scss'

const MenuSelect = () => {
  const setManageModalType = useAppState(state => state.setManageModalType)

  return (
    <div className="menu-select">
      <div className="action-btns-desc">Deposit tokens or buy directly from BNB to get War Pigs.</div>
      <div className="action-btn" onClick={() => { setManageModalType(ManageModalType.BuyMenu) }}>
        <span>Buy</span>
        <div className="action-btn-bg" dangerouslySetInnerHTML={{ __html: modalBlueBtnBorder }}/>
      </div>
      <div className="action-btn" onClick={() => { setManageModalType(ManageModalType.DepositMenu) }}>
        <span>Deposit</span>
        <div className="action-btn-bg" dangerouslySetInnerHTML={{ __html: modalPurpleBtnBorder }}/>
      </div>
      <div className="action-btns-hint">You may also withdraw War Pigs to your wallet.</div>
      <div className="action-btn withdraw" onClick={() => { setManageModalType(ManageModalType.WithdrawMenu) }}>
        <span>Withdraw</span>
        <div className="action-btn-bg" dangerouslySetInnerHTML={{ __html: modalRedBtnBorder }}/>
      </div>
    </div>
  )
}

export default MenuSelect
