import create from 'zustand'

import {
  ISeasonData,
  IPlayerData,
  ManageModalType,
  ISnackbarMessage,
  IBoosterTilesList,
  IWarRoomTeam,
  WarRoomStep,
  ILeaderboardData
} from '../intefraces'

// Mock data
import {
  seasonData,
  playerData,
  boosterTilesLists,
  warRoomTeams,
  seasonsAvailable,
  leaderboardData
} from './appStateMock'

interface IAppState {
  seasonData: ISeasonData,
  setSeasonData: (newValue: ISeasonData) => void

  playerData: IPlayerData,
  setPlayerData: (newValue: IPlayerData) => void

  connectModalOpened: boolean
  setConnectModalOpened: (newValue: boolean) => void

  manageModalType: ManageModalType
  setManageModalType: (newValue: ManageModalType) => void

  snackbarMessage?: ISnackbarMessage
  setSnackbarMessage: (newValue?: ISnackbarMessage) => void

  boosterTilesLists: IBoosterTilesList[]
  setBoosterTilesLists: (newValue: IBoosterTilesList[]) => void

  warRoomTeams: IWarRoomTeam[]
  setWarRoomTeams: (newValue: IWarRoomTeam[]) => void

  warRoomStep: WarRoomStep
  setWarRoomStep: (newValue: WarRoomStep) => void

  seasonsAvailable: string[]
  setSeasonAvailable: (newValue: string[]) => void

  leaderboardData: ILeaderboardData
  setLeaderboardData: (newValue: ILeaderboardData) => void
}

const useAppState = create<IAppState>(set => ({
  seasonData: seasonData,
  setSeasonData: (newValue: ISeasonData) => set({ seasonData: newValue }),

  playerData: playerData,
  setPlayerData: (newValue: IPlayerData) => set({ playerData: newValue }),

  connectModalOpened: false,
  setConnectModalOpened: (newValue: boolean) => set({ connectModalOpened: newValue }),

  manageModalType: ManageModalType.None,
  setManageModalType: (newValue: ManageModalType) => set({ manageModalType: newValue }),

  snackbarMessage: undefined,
  setSnackbarMessage: (newValue?: ISnackbarMessage) => set({ snackbarMessage: newValue }),

  boosterTilesLists: boosterTilesLists,
  setBoosterTilesLists: (newValue: IBoosterTilesList[]) => set({ boosterTilesLists: newValue }),

  warRoomTeams: warRoomTeams,
  setWarRoomTeams: (newValue: IWarRoomTeam[]) => set({ warRoomTeams: newValue }),

  warRoomStep: WarRoomStep.Step1,
  setWarRoomStep: (newValue: WarRoomStep) => set({ warRoomStep: newValue }),

  seasonsAvailable: seasonsAvailable,
  setSeasonAvailable: (newValue: string[]) => set({ seasonsAvailable: newValue }),

  leaderboardData: leaderboardData,
  setLeaderboardData: (newValue: ILeaderboardData) => set({ leaderboardData: newValue })
}))

export default useAppState
