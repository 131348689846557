import React from 'react'

import pageTitleBg from '../../content/page-title-bg.png'

type Props = {
  children?: React.ReactChild
  title?: string
  subtitle?: string
}

// eslint-disable-next-line react/prop-types
const Page: React.FC<Props> = ({ children, title, subtitle }) => {
  return (
    <div className="page-wrapper">
      <div className="page">
        {
          (title || subtitle) &&
          <div className="page-title-wrapper">
            <div className="page-title-inner-wrapper">
              { title && <div className="page-title">{title}</div>}
              { subtitle && <div className="page-subtitle">{subtitle}</div>}
            </div>
            <img className="page-title-bg" src={pageTitleBg}/>
          </div>
        }
        {children}
      </div>
      <div className="page-bg"></div>
    </div>
  )
}

export default Page
