import { SeasonStatus, BoosterTileType, WarRoomTeamType, ILeaderboardData } from '../intefraces'

export const playerData = {
  boosterPacks: 0,
  warpigBalance: 1617285034
}

export const seasonData = {
  number: 2,
  status: SeasonStatus.Open,
  closedAtUTC: '2021-12-01 00:00:00'
}

export const boosterTilesLists = [
  {
    title: 'LEGENDARY NFTs',
    tiles: [
      { type: BoosterTileType.Legendary, title: 'NFT Title', amount: 1, amountType: 'Own', piggyTypeId: 1 },
      { type: BoosterTileType.Legendary, title: 'NFT Title', amount: 1, amountType: 'Own', piggyTypeId: 2 },
      { type: BoosterTileType.Legendary, title: 'NFT Title', amount: 1, amountType: 'Own', piggyTypeId: 3 },
      { type: BoosterTileType.Legendary, title: 'NFT Title', amount: 1, amountType: 'Own', piggyTypeId: 4 },
      { type: BoosterTileType.Legendary, title: 'NFT Title', amount: 1, amountType: 'Own', piggyTypeId: 5 },
      { type: BoosterTileType.Legendary, title: 'NFT Title', amount: 1, amountType: 'Own', piggyTypeId: 1 },
      { type: BoosterTileType.Legendary, title: 'NFT Title', amount: 1, amountType: 'Own', piggyTypeId: 6 }
    ]
  },
  {
    title: 'SEASON 2 SET',
    tiles: [
      { type: BoosterTileType.Default, title: 'NFT Title', number: 1, generalNumber: 7, amount: 1, amountType: 'Own', piggyTypeId: 1 },
      { type: BoosterTileType.Rare, title: 'NFT Title', number: 2, generalNumber: 7, amount: 1, amountType: 'Own', piggyTypeId: 2 },
      { type: BoosterTileType.Default, title: 'NFT Title', number: 3, generalNumber: 7, amount: 1, amountType: 'Own', piggyTypeId: 3 },
      { type: BoosterTileType.Default, title: 'NFT Title', number: 4, generalNumber: 7, amount: 1, amountType: 'Own', piggyTypeId: 4 },
      { type: BoosterTileType.Default, title: 'NFT Title', number: 5, generalNumber: 7, amount: 1, amountType: 'Own', piggyTypeId: 5 },
      { type: BoosterTileType.Default, title: 'NFT Title', number: 6, generalNumber: 7, amount: 1, amountType: 'Own', piggyTypeId: 1 },
      { type: BoosterTileType.Default, title: 'NFT Title', number: 7, generalNumber: 7, amount: 1, amountType: 'Own', piggyTypeId: 6 }
    ]
  },
  {
    title: 'SEASON 1 SET',
    tiles: [
      { type: BoosterTileType.Default, title: 'NFT Title', number: 1, generalNumber: 7, amount: 1, amountType: 'Own', piggyTypeId: 1 },
      { type: BoosterTileType.Rare, title: 'NFT Title', number: 2, generalNumber: 7, amount: 1, amountType: 'Own', piggyTypeId: 2 },
      { type: BoosterTileType.Default, title: 'NFT Title', number: 3, generalNumber: 7, amount: 1, amountType: 'Own', piggyTypeId: 3 },
      { type: BoosterTileType.Default, title: 'NFT Title', number: 4, generalNumber: 7, amount: 1, amountType: 'Own', piggyTypeId: 4 },
      { type: BoosterTileType.Default, title: 'NFT Title', number: 5, generalNumber: 7, amount: 1, amountType: 'Own', piggyTypeId: 5 },
      { type: BoosterTileType.Default, title: 'NFT Title', number: 6, generalNumber: 7, amount: 1, amountType: 'Own', piggyTypeId: 1 },
      { type: BoosterTileType.Default, title: 'NFT Title', number: 7, generalNumber: 7, amount: 1, amountType: 'Own', piggyTypeId: 6 }
    ]
  }
]

export const warRoomTeams = [
  { id: 100500, type: WarRoomTeamType.Default, title: 'PIGGYBANK', piggyTypeId: 1, damagePoints: 0 },
  { id: 100501, type: WarRoomTeamType.Default, title: 'PIGGYBANK', piggyTypeId: 2, damagePoints: 0 },
  { id: 100502, type: WarRoomTeamType.Default, title: 'PIGGYBANK', piggyTypeId: 3, damagePoints: 0 },
  { id: 100503, type: WarRoomTeamType.Default, title: 'PIGGYBANK', piggyTypeId: 4, damagePoints: 0 },
  { id: 100504, type: WarRoomTeamType.Default, title: 'PIGGYBANK', piggyTypeId: 5, damagePoints: 0 },
  { id: 100505, type: WarRoomTeamType.Default, title: 'PIGGYBANK', piggyTypeId: 6, damagePoints: 0 }
]

export const seasonsAvailable = ['3', '2', '1']

export const leaderboardData: ILeaderboardData = {
  activity: [
    { title: 'player name_name_228_322', totalNFTs: '125 655 488', totalLegendaries: '999 999 999', totalVolume: '999 999 999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '125 655 588' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '125 655' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '12 555' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '1 255' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' },
    { title: 'player name', totalNFTs: '999', totalLegendaries: '999', totalVolume: '999' }
  ],
  player: [
    { title: 'player name_name_228_322', totalVolume: '125 655 588' },
    { title: 'player name', totalVolume: '12 565 558' },
    { title: 'player name', totalVolume: '655 158' },
    { title: 'player name', totalVolume: '15 158' },
    { title: 'player name', totalVolume: '1 158' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' },
    { title: 'player name', totalVolume: '999' }
  ],
  team: [
    { title: 'player name_name_228_322', wins: '999 999', totalVolume: '125 655 588' },
    { title: 'player name', wins: '999', totalVolume: '12 565 558' },
    { title: 'player name', wins: '999', totalVolume: '655 158' },
    { title: 'player name', wins: '999', totalVolume: '15 158' },
    { title: 'player name', wins: '999', totalVolume: '1 158' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' },
    { title: 'player name', wins: '999', totalVolume: '999' }
  ]
}
