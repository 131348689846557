import React from 'react'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import CloseIcon from '@material-ui/icons/Close'

import MenuSelect from './menu-select'
import BuyMenu from './buy-menu'
import DepositMenu from './deposit-menu'
import WithdrawMenu from './withdraw-menu'

import { ManageModalType } from '../../../intefraces'

import useAppState from '../../../services/appState'

import modalBg from '../../../content/modal-bg.png'
import piggy from '../../../content/piggies/pig2.png'

import './styles.scss'

const ManageModal = () => {
  const playerData = useAppState(state => state.playerData)
  const manageModalType = useAppState(state => state.manageModalType)
  const setManageModalType = useAppState(state => state.setManageModalType)

  return (
    <>
      <Modal
        open={manageModalType !== ManageModalType.None}
        BackdropComponent={Backdrop}
        className={`manage-modal modal ${manageModalType}`}
        onClose={() => { setManageModalType(ManageModalType.None) }}
      >
        <div className="modal-content">
          <CloseIcon className="modal-close-btn" onClick={() => { setManageModalType(ManageModalType.None) }}/>
          <div className="logo-wrapper">
            <img src={piggy} alt="Header logo"/>
          </div>
          <div className="title">WAR PIGGS</div>
          { manageModalType !== ManageModalType.MenuSelect &&
            <div className="subtitle">
              { manageModalType === ManageModalType.BuyMenu && 'Buy Piggy Bank Token' }
              { manageModalType === ManageModalType.DepositMenu && 'Deposit Piggy Bank Token' }
              { manageModalType === ManageModalType.WithdrawMenu && 'Withdraw Piggy Bank Token' }
            </div>
          }
          <div className="balance">
            <span>War Pigg Balance:</span>
            <span className="value">{playerData.warpigBalance}</span>
          </div>
          { manageModalType === ManageModalType.MenuSelect && <MenuSelect /> }
          { manageModalType === ManageModalType.BuyMenu && <BuyMenu /> }
          { manageModalType === ManageModalType.DepositMenu && <DepositMenu /> }
          { manageModalType === ManageModalType.WithdrawMenu && <WithdrawMenu /> }
          <img className="modal-bg" src={modalBg} />
        </div>
      </Modal>
    </>
  )
}

export default ManageModal
