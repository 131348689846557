import React from 'react'
import MuiSnackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import useAppState from '../../services/appState'

import './styles.scss'

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Snackbar = () => {
  const snackbarMessage = useAppState(state => state.snackbarMessage)
  const setSnackbarMessage = useAppState(state => state.setSnackbarMessage)

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarMessage()
  }

  return (
    <MuiSnackbar className="snackbar" open={!!snackbarMessage} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={snackbarMessage?.isError ? 'error' : 'success'}>
        <div className="main-txt">{snackbarMessage?.mainTxt}</div>
        { snackbarMessage && snackbarMessage.additionalTxt && <div className="additional-txt">{snackbarMessage.additionalTxt}</div> }
      </Alert>
    </MuiSnackbar>
  )
}

export default Snackbar
