/* eslint-disable no-unused-vars */
export enum SeasonStatus {
  Open = 'open',
  Closed = 'closed'
}
/* eslint-enable no-unused-vars */

export interface ISeasonData {
  number: number
  status: SeasonStatus
  closedAtUTC?: string
}

export interface IPlayerData {
  boosterPacks: number
  warpigBalance: number
}

/* eslint-disable no-unused-vars */
export enum ManageModalType {
  None = 'None',
  MenuSelect = 'menu-select',
  BuyMenu = 'buy-menu',
  DepositMenu = 'deposit-menu',
  WithdrawMenu = 'withdraw-menu'
}
/* eslint-enable no-unused-vars */

export interface ISnackbarMessage {
  mainTxt: string
  additionalTxt?: string
  isError?: boolean
}

/* eslint-disable no-unused-vars */
export enum BoosterTileType {
  Default = 'default',
  Rare = 'rare',
  Legendary = 'legendary'
}
/* eslint-enable no-unused-vars */

export interface IBoosterTile {
  type: BoosterTileType
  title: string
  number?: number
  generalNumber?: number
  amount: number
  amountType: string
  piggyTypeId: number
}

export interface IBoosterTilesList {
  title: string
  tiles: IBoosterTile[]
}

/* eslint-disable no-unused-vars */
export enum WarRoomTeamType {
  Default = 'default',
  Team = 'team'
}
/* eslint-enable no-unused-vars */

export interface IWarRoomTeam {
  id: number
  type: WarRoomTeamType
  title: string
  piggyTypeId: number
  damagePoints: number
}

/* eslint-disable no-unused-vars */
export enum WarRoomStep {
  Step1 = 'step1',
  Step2 = 'step2',
  Step3 = 'step3',
  Step4 = 'step4',
}
/* eslint-enable no-unused-vars */

/* eslint-disable no-unused-vars */
export enum LeaderboardType {
  Activity = 'activity',
  Player = 'player',
  Team = 'team'
}
/* eslint-enable no-unused-vars */

export interface LeaderboardMatchParams {
  season?: string
  type?: LeaderboardType
}

export interface ILeaderboardActivityData {
  title: string
  totalNFTs: string
  totalLegendaries: string
  totalVolume: string
}

export interface ILeaderboardPlayerData {
  title: string
  totalVolume: string
}

export interface ILeaderboardTeamData {
  title: string
  wins: string
  totalVolume: string
}

export interface ILeaderboardData {
  activity: ILeaderboardActivityData[]
  player: ILeaderboardPlayerData[]
  team: ILeaderboardTeamData[]
}
