import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import './styles.scss'

import { SeasonStatus, ManageModalType } from '../../intefraces'
import useAppState from '../../services/appState'
import { formatNumberToString, bigNumberFormatter, deepCopy } from '../../services/misc'

import Menu from './menu'
import ConnectModal from './connect'
import ManageModal from './manage'

import { seasonBorder, seasonBorderSm, clockIcon, connectionBorder, connectionBorderSm, userIcon, actionBtn } from '../../content/svgs'

const buildRemainingStr = (finishAtUTC?: string) => {
  if (!finishAtUTC) return '00d 00h 00m'

  const now = moment()
  const finish = moment.utc(finishAtUTC)
  const duration = moment.duration(finish.diff(now))

  const hours = duration.hours()
  duration.subtract(moment.duration(hours, 'hours'))

  const minutes = duration.minutes()
  duration.subtract(moment.duration(minutes, 'minutes'))

  const seconds = duration.seconds()
  duration.subtract(moment.duration(seconds, 'seconds'))

  return `${formatNumberToString(hours, 2)}:${formatNumberToString(minutes, 2)}:${formatNumberToString(seconds, 2)}`
}

const Header = () => {
  const seasonData = useAppState(state => state.seasonData)
  const setSeasonData = useAppState(state => state.setSeasonData)
  const playerData = useAppState(state => state.playerData)
  const setManageModalType = useAppState(state => state.setManageModalType)
  const setConnectModalOpened = useAppState(state => state.setConnectModalOpened)

  const [remainingStr, setRemainingStr] = useState<string>(buildRemainingStr(seasonData.closedAtUTC))

  useEffect(() => {
    const timer = setInterval(() => {
      if (moment() < moment(seasonData.closedAtUTC)) {
        setRemainingStr(buildRemainingStr(seasonData.closedAtUTC))
      } else {
        const newSeasonData = deepCopy(seasonData)
        newSeasonData.status = SeasonStatus.Closed
        setSeasonData(newSeasonData)
      }
    }, 1000)
    return () => { clearTimeout(timer) }
  })

  return (
    <>
      <div className="header-wrapper">
        <div className="header-bg"></div>
        <div className="header">
          <Link to="/" className="logo-wrapper">
            <img src="/content/logo.png" alt="Header logo"/>
          </Link>
          <div className={`season ${seasonData.status}`}>
            <div className="info-wrapper">
              <span>SEASON: </span>
              <span className="number">{seasonData.number}</span>
              <span className="status">{seasonData.status}</span>
            </div>
            {
              seasonData.status === SeasonStatus.Open &&
                <div className="countdown-wrapper">
                  <div className="countdown-clock" dangerouslySetInnerHTML={{ __html: clockIcon }}/>
                  <span className="countdown">{remainingStr}</span>
                </div>
            }
            <div className="season-bg" dangerouslySetInnerHTML={{ __html: seasonBorder }}/>
            <div className="season-bg season-bg-sm" dangerouslySetInnerHTML={{ __html: seasonBorderSm }}/>
          </div>
          <div className="center-align-wrapper">
            <div className="center-align">
              <div className="boosters">
                <div className="info-wrapper">
                  <span>Booster Packs: </span>
                  <span className="number">{formatNumberToString(playerData.boosterPacks, 2)}</span>
                </div>
                <Link to="/locker" className="action-btn">
                  CLAIM
                  <div className="action-btn-bg" dangerouslySetInnerHTML={{ __html: actionBtn }} />
                </Link>
              </div>
              <div className="balance">
                <div className="info-wrapper">
                  <span>War Pigs Balance: </span>
                  <span className="number">{bigNumberFormatter(playerData.warpigBalance)}</span>
                </div>
                <div className="action-btn" onClick={() => { setManageModalType(ManageModalType.MenuSelect) }}>
                  MANAGE
                  <div className="action-btn-bg" dangerouslySetInnerHTML={{ __html: actionBtn }} />
                </div>
              </div>
            </div>
          </div>
          <div className="right-align">
            <div
              className="connection"
              onClick={() => { setConnectModalOpened(true) }
            }>
              <div className="connection-img" dangerouslySetInnerHTML={{ __html: userIcon }}/>
              <span className="connection-hash">0xDEas … Jl12Xs</span>
              <span className="connection-hash connection-hash-sm">0x78 … Rrj</span>
              <div className="connection-bg" dangerouslySetInnerHTML={{ __html: connectionBorder }}/>
              <div className="connection-bg connection-bg-sm" dangerouslySetInnerHTML={{ __html: connectionBorderSm }}/>
            </div>
            <Menu />
          </div>
        </div>
      </div>
      <div className="mobile-header-wrapper">
        <div className="mobile-header-inner-wrapper">
          <div className="mobile-header">
            <Link to="/locker" className="boosters">
              <div className="info-wrapper">
                <span>Booster Packs: </span>
                <span className="number">{formatNumberToString(playerData.boosterPacks, 2)}</span>
              </div>
            </Link>
            <div className="balance" onClick={() => { setManageModalType(ManageModalType.MenuSelect) }}>
              <div className="info-wrapper">
                <span>War Pigs Balance: </span>
                <span className="number">{bigNumberFormatter(playerData.warpigBalance)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ManageModal />
      <ConnectModal />
    </>
  )
}

export default Header
