import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'

import { useOutsideClick } from '../../services/misc'

import { menuBg, menuBtnBorder } from '../../content/svgs'

import './menu.scss'

const Menu = () => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false)

  const ref = useRef<HTMLDivElement | null>(null)
  useOutsideClick(ref, () => {
    setIsMenuOpened(false)
  })

  return (
    <div className="menu-wrapper">
      <MenuIcon
        className="icon"
        onClick={() => { setIsMenuOpened(!isMenuOpened) }}
      />
      { isMenuOpened &&
        <div ref={ref} className="menu-inner-wrapper">
          <div className="menu">
            <Link to="/" className="item" onClick={() => { setIsMenuOpened(!isMenuOpened) }}>
              <div className="item-txt">Dashboard</div>
              <div className="item-bg" dangerouslySetInnerHTML={{ __html: menuBtnBorder }}/>
            </Link>
            <Link to="/war-room" className="item" onClick={() => { setIsMenuOpened(!isMenuOpened) }}>
              <div className="item-txt">The War Room</div>
              <div className="item-bg" dangerouslySetInnerHTML={{ __html: menuBtnBorder }}/>
            </Link>
            <Link to="/locker" className="item" onClick={() => { setIsMenuOpened(!isMenuOpened) }}>
              <div className="item-txt">NFT Locker</div>
              <div className="item-bg" dangerouslySetInnerHTML={{ __html: menuBtnBorder }}/>
            </Link>
            <Link to="/how-to-play" className="item" onClick={() => { setIsMenuOpened(!isMenuOpened) }}>
              <div className="item-txt">How to Play</div>
              <div className="item-bg" dangerouslySetInnerHTML={{ __html: menuBtnBorder }}/>
            </Link>
            <Link to="/leaderboard" className="item" onClick={() => { setIsMenuOpened(!isMenuOpened) }}>
              <div className="item-txt">Leaderboard</div>
              <div className="item-bg" dangerouslySetInnerHTML={{ __html: menuBtnBorder }}/>
            </Link>
            <div className="menu-bg" dangerouslySetInnerHTML={{ __html: menuBg }}/>
          </div>
        </div>
      }
    </div>
  )
}

export default Menu
